.logo {
  color: #8cb4e9;
  padding-left: 20px;
  text-align: left;
  vertical-align: auto;
}

.topMenu {
  align-items: center;
  background-color: #052977;
  display: flex;
  height: 64px;
  margin: 0px;
  padding: 0px;
}