.homepage {
  background: #8cb4e9;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.underConstruction {
  color: #032555;
}
